import React, { Fragment, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { actualizar_tokenfb_usuario } from "../../src/redux/actions/profile";
import { limpiar_data_lista_compra } from "../../src/redux/actions/shoppingList";
import "../assets/styles/mystyle.scss";
import Banner from "../components/home/Banner";
import Capsula from "../components/home/Capsula";
import Capsula2 from "../components/home/Capsula2";
import Capsula3 from "../components/home/Capsula3";
import CarouselProducto from "../components/home/CarouselProducto";
import ListaCompra from "../components/home/ListaCompra";
import Pedidos from "../components/home/Pedidos";
import {
  DEFAULT_KEYWORDS,
  HomeSEO,
  KEYWORD_BY_SERVICE_TYPE,
} from "../components/SEOComponents/HomeSEO";
import Alert from "../components/Swalert";
import slug from "../components/Utils/slug";
import Layout from "../hocs/Layout";
import { useOrganizationJSONLd } from "../hooks/richSnippets/useOrganizationJSONLd";
import { useStoreJSONLd } from "../hooks/richSnippets/useStoreJSONLd";
import { useGetCurrentUrl } from "../hooks/useGetCurrentUrl";
import { useGetSucursalCiudad } from "../hooks/useGetSucursalCiudad";
import {
  get_carrito_por_sucursal,
  store_carrito,
} from "../redux/actions/carrito";
import { get_homepage } from "../redux/actions/home";
import BuscadorGeneral from "../widgets/BuscadorGeneral";
import LoaderSpinner from "../widgets/loaderSpinner";
import { BotonContacto } from "../components/home/BotonContacto";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";
import { useGetSucursalContactoMediaLinks } from "../modules/sucursal-contactos/ui/hooks/useGetSucursalContactoMediaLinks";
import Leyenda from "./Leyenda";
// import { useStoreJSONLd } from "../hooks/richSnippets/useStoreJSONLd";

const MOSTRAR_CRONOMETRO = true;
const Home = ({
  get_homepage,
  sucursal,
  cambio_sucursal,
  novedades,
  mas_vendidos,
  store_carrito,
  carritos,
  cambio_carrito,
  direccion,
  tipo_entrega,
  producto_delete,
  get_carrito_por_sucursal,
  profile,
  homes,
  bool_delete_pedido,
  actualizar_tokenfb_usuario,
  limpiar_data_lista_compra,
  carrito_favorito,
  cambio_detalle_carrito,
  isFetchingHomes,
}) => {
  const { sucursalContactoMediaLinks } = useGetSucursalContactoMediaLinks();

  const { isDefaultStore } = useIsDefaultStore();
  const { baseUrl, currentUrl } = useGetCurrentUrl();

  const organization = {
    url: baseUrl,
    logo: process.env.REACT_APP_ORGANIZATION_LOGO,
    name: process.env.REACT_APP_ORGANIZATION_NAME,
    mediaLinks:
      sucursalContactoMediaLinks?.map((mediaLink) => mediaLink.url) || [],
    contactPoint: [
      { telephone: process.env.REACT_APP_ORGANIZATION_PHONE_NUMBER },
    ],
  };
  const { sucursalCiudad } = useGetSucursalCiudad({
    idRegion: sucursal.idRegion,
  });
  const { sucursalp, ciudadp } = useParams();
  const isStoredSucursal = useMemo(
    () => slug.data_slug(sucursal?.Descripcion || "") === sucursalp,
    [sucursalp, sucursal]
  );

  const arraySeccion = useMemo(() => {
    return homes?.Secciones?.filter((ss) => ss.IdSeccion !== 1);
  }, [homes]);
  useEffect(() => {
    if (!homes && sucursal && sucursalp && isStoredSucursal) {
      get_homepage(sucursal.IdSucursal, sucursal.IdMarket, tipo_entrega.Id);
    }
  }, [isStoredSucursal, sucursal, sucursalp, homes, tipo_entrega]);

  const { organizationJSONLdScript } = useOrganizationJSONLd(organization);
  const { storeJSONLdScript } = useStoreJSONLd(
    {
      addressCountry: "Bolivia",
      addressLocality: sucursalCiudad.Nombre,
      addressRegion: sucursalCiudad.Nombre,
      name: sucursal.Descripcion,
      openingHours: process.env.REACT_APP_STORE_OPENING_HOURS,
      streetAddress: sucursal.Direccion,
      telephone: `+${sucursal.ContactoWhatsapp}`,
      url: currentUrl,
      latitude: sucursal.Latitud,
      longitude: sucursal.Longitud,
      tipoServicio: sucursal?.TipoServicio.Descripcion.toUpperCase(),
    },
    organization
  );

  const displaySEO = !!sucursal && !!sucursalCiudad;

  return (
    <>
      {displaySEO && (
        <HomeSEO
          title={`${
            isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion
          } - Compras en línea`}
          store={`${sucursal?.Descripcion}`}
          description={`Realiza tus compras en línea en ${
            !sucursal || isDefaultStore
              ? "tu sucursal Hipermaxi más cercana"
              : `la sucursal ${sucursal.Descripcion}`
          } en Bolivia. Mediante unos simples pasos, envía tus productos al carrito de compra, decide cómo pagar y recíbelos en la puerta de tu casa. Disfruta de la conveniencia de ${
            !sucursal || isDefaultStore
              ? "nuestra sucursal"
              : `${
                  KEYWORD_BY_SERVICE_TYPE[
                    sucursal?.TipoServicio.Descripcion.toUpperCase()
                  ]
                }`
          } en línea Hipermaxi y encuentra una amplia variedad de productos locales, productos importados y ofertas exclusivas.`}
          organizationSnippet={organizationJSONLdScript}
          storeSnippet={storeJSONLdScript}
          image={`${sucursal?.UrlFoto}&size=400x400`}
          url={currentUrl}
          keywords={[
            `${process.env.REACT_APP_ORGANIZATION_NAME} ${
              sucursalCiudad?.Nombre || ""
            }`,
            sucursal?.Descripcion || "",
            `${sucursal?.TipoServicio.Descripcion || ""} ${
              sucursalCiudad?.Nombre || ""
            }`,
            `${sucursal?.TipoServicio.Descripcion || ""} online Bolivia`,
            `Tienda online ${sucursal?.Descripcion}`,
            `Compras online ${sucursalCiudad?.Nombre || ""}`,
            `Entrega a domicilio ${sucursalCiudad?.Nombre || ""}`,
            `Compras en línea ${sucursalCiudad?.Nombre || ""}`,
            `Ofertas ${sucursal?.Descripcion}`,
            `Descuentos ${sucursal?.Descripcion}`,
            `Marcas exclusivas ${sucursal?.Descripcion}`,
            `Promociones ${sucursal?.Descripcion}`,
            `Ofertas especiales ${sucursalCiudad?.Nombre || ""}`,
            `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} Bolivia`,
            `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
              sucursalCiudad?.Nombre
            }`,
            `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
              sucursal?.Descripcion
            }`,
            DEFAULT_KEYWORDS,
          ]}
        />
      )}

      <Layout>
        <Alert></Alert>
        <div className="block sticky top-[108px] md:top-[120px] z-10">
          <Leyenda />

          <div className="lg:hidden p-2 bg-white">
            <BuscadorGeneral />
          </div>
        </div>
        <Pedidos />
        <div
          className="container mx-auto relative z-[-1] flex flex-col gap-4 md:gap-8 md:!pt-4"
          style={{
            paddingTop: 4,
          }}
        >
          {!arraySeccion ? (
            <div className="mt-4">
              <LoaderSpinner bool={true} />
            </div>
          ) : (
            <>
              {arraySeccion?.map((seccion) => (
                <Fragment key={`home-seccion-${seccion.IdSeccion}$`}>
                  {!seccion.EsPublicidad &&
                    seccion.IdTipoSeccion === 2 &&
                    seccion.ProductosOferta && (
                      <CarouselProducto
                        descripcion={seccion.Descripcion}
                        Items={seccion.ProductosOferta}
                        ps_seccion={seccion}
                        ps_IdSucursal={sucursal.IdSucursal}
                        mostrarCronometro={MOSTRAR_CRONOMETRO}
                      />
                    )}
                  {!seccion.EsPublicidad && seccion.IdTipoSeccion === 14 && (
                    <Banner
                      descripcion={seccion.Descripcion}
                      Items={seccion.Banner}
                      ps_seccion={seccion}
                      ps_IdSucursal={sucursal.IdSucursal}
                      idSeccion={seccion.IdSeccion}
                      mostrarCronometro
                    />
                  )}
                  {!seccion.EsPublicidad && seccion.IdTipoSeccion === 15 && (
                    <Banner
                      descripcion={seccion.Descripcion}
                      Items={seccion.Banner}
                      ps_seccion={seccion}
                      ps_IdSucursal={sucursal.IdSucursal}
                    />
                  )}
                  {!seccion.EsPublicidad && seccion.IdTipoSeccion === 6 && (
                    <CarouselProducto
                      descripcion={seccion.Descripcion}
                      Items={seccion.ProductosMasVendidos}
                      ps_seccion={seccion}
                      ps_IdSucursal={sucursal.IdSucursal}
                    />
                  )}
                  {!seccion.EsPublicidad && seccion.IdTipoSeccion === 7 && (
                    <CarouselProducto
                      descripcion={seccion.Descripcion}
                      Items={seccion.ProductosMasVendidosCliente}
                      ps_seccion={seccion}
                      ps_IdSucursal={sucursal.IdSucursal}
                    />
                  )}
                  {seccion.IdTipoSeccion === 8 && <ListaCompra />}
                  {seccion.IdTipoSeccion === 10 &&
                    seccion.ProductosTemporada.length > 0 && (
                      <CarouselProducto
                        descripcion={seccion.Descripcion}
                        ps_seccion={seccion}
                        Items={seccion.ProductosTemporada}
                        ps_IdSucursal={sucursal.IdSucursal}
                      />
                    )}
                  {seccion.IdTipoSeccion === 11 &&
                    seccion.Capsula.length > 0 && (
                      <Capsula
                        descripcion={seccion.Descripcion}
                        Items={seccion.Capsula}
                        idSeccion={seccion.IdSeccion}
                      />
                    )}
                  {seccion.IdTipoSeccion === 12 &&
                    seccion.CustomCapsula !== null &&
                    seccion.CustomCapsula.length > 0 && (
                      <Capsula2
                        descripcion={seccion.Descripcion}
                        Items={seccion.CustomCapsula}
                        idSeccion={seccion.IdSeccion}
                      />
                    )}
                  {seccion.IdTipoSeccion === 13 &&
                    seccion.Capsula.length > 0 && (
                      <Capsula3
                        descripcion={seccion.Descripcion}
                        Items={seccion.Capsula}
                      />
                    )}
                </Fragment>
              ))}
            </>
          )}
          <BotonContacto telefono={sucursal?.ContactoWhatsapp || ""} />
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  homes: state.Home.homes,
  isFetchingHomes: state.Home.isFetchingHomes,
  novedades: state.Home.novedades,
  mas_vendidos: state.Home.mas_vendidos,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  carritos: state.Carrito.carrito,
  bool_delete_pedido: state.Carrito.bool_delete_pedido,
  producto_delete: state.Carrito.producto_delete,
  profile: state.Profile.profile,
  cambio_carrito: state.Carrito.cambio_carrito,
  cambio_sucursal: state.BranchOffice.cambio_sucursal,
  carrito_favorito: state.Carrito.carrito_favorito,
  cambio_detalle_carrito: state.Carrito.cambio_detalle_carrito,
});

export default connect(mapStateToProps, {
  store_carrito,
  get_homepage,
  get_carrito_por_sucursal,
  actualizar_tokenfb_usuario,
  limpiar_data_lista_compra,
})(Home);
