import { useSelector } from "react-redux";
import { SucursalContacto } from "../../../sucursal-contactos/domain/interface/sucursal-contacto";

export function useGetContactInfoLeyenda() {
  const sucursalContacto = useSelector(
    (state: { SucursalContactos: { sucursalContacto: SucursalContacto } }) =>
      state.SucursalContactos.sucursalContacto
  );

  const isLoadingSucursalContacto = useSelector(
    (state: { SucursalContactos: { isLoading: boolean } }) =>
      state.SucursalContactos.isLoading
  );

  const sucursalContactoLeyenda = sucursalContacto?.Leyenda?.trim();
  const sucursalContactoLeyendaColorFondo = sucursalContacto?.LeyendaColorFondo?.trim();
  const sucursalContactoLeyendaColorLetra = sucursalContacto?.LeyendaColorLetra?.trim();
  return {
    sucursalContactoLeyenda,
    sucursalContactoLeyendaColorFondo,
    sucursalContactoLeyendaColorLetra,
    isLoadingSucursalContacto,
  };
}
