import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemProducto from "../../widgets/item_producto";
import {
  faAngleLeft,
  faAngleRight,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import Chronometer from "../timer/chronometer";
import { useStartChronometer } from "../../hooks/use-start-chronometer";

const breakpoints = {
  300: {
    slidesPerView: 2,
    spaceBetween: 16,
    slidesPerGroup: 2,
  },

  400: {
    slidesPerView: 2,
    spaceBetween: 16,
    slidesPerGroup: 2,
  },

  640: {
    slidesPerView: 3,
    spaceBetween: 16,
    slidesPerGroup: 3,
  },
  768: {
    slidesPerView: 4,
    spaceBetween: 16,
    slidesPerGroup: 4,
  },

  992: {
    slidesPerView: 5,
    spaceBetween: 24,
    slidesPerGroup: 5,
  },
  1200: {
    slidesPerView: 6,
    spaceBetween: 24,
    slidesPerGroup: 6,
  },
  1400: {
    slidesPerView: 6,
    spaceBetween: 24,
    slidesPerGroup: 6,
  },
};

const CarouselProducto = ({
  descripcion,
  Items,
  ps_seccion,
  ps_IdSucursal,
  mostrarCronometro,
}) => {
  
  const { ciudadp, sucursalp } = useParams();
  const productos = useMemo(
    () => (Items.Productos ? Items.Productos : Items),
    [Items]
  );

  const shouldLoop =
    productos.length >
    Math.max(...Object.values(breakpoints).map((bp) => bp.slidesPerView));

  const {startChronometer, chronometerEndDate} = useStartChronometer({
    startDate: ps_seccion?.ProductosOferta?.FechaInicioCronometroHomepage,
    endDate: ps_seccion?.ProductosOferta?.FechaFin,
  });
  const displayTimer =
    mostrarCronometro &&
    startChronometer;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center pr-2">
        <div className="flex gap-[0.25rem] md:gap-3 flex-grow md:items-end flex-col md:flex-row">
          {ps_seccion !== undefined && ps_seccion.Api !== null ? (
            <Link
              className="hover:underline"
              to={`/${ciudadp + "/" + sucursalp}/seccion/${
                ps_seccion.IdSeccion
              }`}
            >
              <h3 className="text-hiperblue text-xl mb-0">{descripcion}</h3>
            </Link>
          ) : (
            <h3 className="text-hiperblue text-xl mb-0">{descripcion}</h3>
          )}
          {displayTimer && (
            <div className="hidden md:block">
              <Chronometer expiryTimestamp={chronometerEndDate} size="small" />
            </div>
          )}
        </div>

        {ps_seccion !== undefined && ps_seccion.Api !== null && (
          <Link
            to={`/${ciudadp + "/" + sucursalp}/seccion/${ps_seccion.IdSeccion}`}
            className="text-center text-hiperorange flex gap-2 items-center hover:underline"
          >
            <span className="flex items-center style_icon_rigth_header">
              Ver más
            </span>
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                height: "20px",
              }}
            />
          </Link>
        )}
      </div>
      {displayTimer && (
        <div className="flex md:hidden">
          <Chronometer expiryTimestamp={chronometerEndDate} size="small" />
        </div>
      )}
      {productos?.length > 0 && (
        <div className=" w-100">
          <div className=" h-auto w-[100%] flex gap-1 relative">
            <button
              // className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 md:shadow-sm  md:bg-gray-50  hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${ps_seccion.IdSeccion} rounded-l-lg`}
              className={`absolute z-10 top-0 bottom-10 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${ps_seccion.IdSeccion} rounded-l-lg`}
            >
              <FontAwesomeIcon icon={faAngleLeft} size="xl" />
            </button>
            <Swiper
              modules={[Navigation, Pagination]}
              breakpoints={breakpoints}
              slidesPerView={1}
              navigation={{
                nextEl: `.review-swiper-button-next-${ps_seccion.IdSeccion}`,
                prevEl: `.review-swiper-button-prev-${ps_seccion.IdSeccion}`,
              }}
              pagination={false}
              scrollbar={{ draggable: true }}
              className="mySwiper w-full"
              loop={shouldLoop}
              loopFillGroupWithBlank={shouldLoop}
              noSwipingSelector="button"
            >
              {productos.map((producto, index) => (
                <SwiperSlide key={index} className=" mySwiper">
                  <ItemProducto
                    key={index}
                    producto={producto}
                    ps_IdSucursal={ps_IdSucursal}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              className={`absolute z-10 top-0 bottom-10 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${ps_seccion.IdSeccion} rounded-r-lg`}
            >
              <FontAwesomeIcon icon={faAngleRight} size="xl" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CarouselProducto);
