type StartChronometerProps = {
  startDate?: Date | string;
  endDate?: Date | string;
};
export function useStartChronometer({
  startDate,
  endDate,
}: StartChronometerProps) {
  const todayDate = new Date();
  const chronometerStartDate = !!startDate ? new Date(startDate) : undefined;
  const chronometerEndDate = !!endDate ? new Date(endDate) : undefined;
  const startChronometer =
    chronometerStartDate !== undefined &&
    chronometerEndDate !== undefined &&
    todayDate.getTime() >= chronometerStartDate.getTime() &&
    todayDate.getTime() <= chronometerEndDate.getTime();
  return {
    startChronometer,
    chronometerStartDate,
    chronometerEndDate,
    todayDate,
  };
}
