import React, { useState } from "react";
import { useTimer } from "react-timer-hook";
import Timer from "./timer";
import classNames from "classnames";

type ChronometerProps = {
  expiryTimestamp: Date;
  interval?: number;
  size?: "big" | "small";
};

const Chronometer: React.FC<ChronometerProps> = ({
  expiryTimestamp,
  interval,
  size,
}) => {
  const isSmall = size === "small";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expired, setExpired] = useState(false);

  const {
    milliseconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    // start,
    // pause,
    // resume,
    // restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => setExpired(true),
    interval,
  });
  
  if (!isRunning) return null;
  return (
    <div
      className={classNames("flex gap-x-2 gap-y-1 rounded-md", {
        "flex-col": isSmall,
        "items-center": !isSmall,
      })}
    >
      {/* {!isSmall && ( */}
        <div
          className={classNames("font-semibold text-black", {
            "text-xs md:text-base": isSmall,
            "text-xs md:text-sm": isSmall,
          })}
        >
          Finaliza:
        </div>
      {/* )} */}
      <Timer
        milliseconds={milliseconds}
        seconds={seconds}
        minutes={minutes}
        hours={hours}
        days={days}
        enableMilliseconds={!!interval && interval < 1000}
        size={size}
      />
    </div>
  );
};

export default Chronometer;
