import React, { useState } from "react";
import { connect } from "react-redux";
import { get_banners } from "../../redux/actions/home";
import {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
} from "../../redux/actions/carrito";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faPause,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { LazyLoadImage } from "../lazyLoadImage";
import BannerItem from "./BannerItem";

const breakpoints = {
  400: {
    slidesPerView: 1,
    spaceBetween: 1,
  },
};
const Banner = ({
  descripcion,
  Items,
  idSeccion,
  limpiar_carrito,
  carritos,
  producto_delete,
  direccion,
  tipo_entrega,
  store_carrito,
  limpiar_solo_carrito,
  mostrarCronometro = false
}) => {
  const [swiperRef, setSwiperRef] = useState(null);
  // const [isPlaying, setIsPlaying] = useState(true);

  // const playSwiper = () => {
  //   swiperRef.autoplay.start();
  //   setIsPlaying(true);
  // };
  // const pauseSwiper = () => {
  //   swiperRef.autoplay.stop();
  //   setIsPlaying(false);
  // };

  const redirectToSucursal = (url) => {
    window.location.href = url;
  };

  const handleGoToSucursal = async (url) => {
    const localStorageSucursal = localStorage.getItem("sucursal");
    const previousSucursal = localStorageSucursal
      ? JSON.parse(localStorageSucursal)
      : null;
    if (carritos && carritos.length > 0 && previousSucursal) {
      await store_carrito(
        previousSucursal,
        tipo_entrega,
        direccion,
        carritos,
        producto_delete,
        false
      );
      limpiar_solo_carrito();
    }
    await limpiar_carrito();
    // get_carrito_por_sucursal(sucursal);
    redirectToSucursal(url);
  };
  // const [currentBanner, setCurrentBanner] = useState(undefined);

  // const todayDate = new Date();
  // const bannerDate = !!currentBanner?.FechaFin ? new Date(currentBanner.FechaFin) : undefined;
  // const displayTimer = mostrarCronometro &&
  // currentBanner?.MostrarCronometro &&
  //   bannerDate !== undefined &&
  //   todayDate.getTime() <= bannerDate.getTime();
  return (
    <>
      {Items?.length > 0 && (
        <div className="container p-0 mx-auto flex gap-1 relative">
          <button
            style={{
              display: Items?.length > 1 ? "block" : "none",
            }}
            className={`absolute z-10 top-0 bottom-0 p-2 md:static text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-prev-${idSeccion} rounded-l-lg`}
          >
            <FontAwesomeIcon icon={faAngleLeft} size="xl" />
          </button>
          <Swiper
            onSwiper={setSwiperRef}
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
            navigation={{
              nextEl: `.review-swiper-button-next-${idSeccion}`,
              prevEl: `.review-swiper-button-prev-${idSeccion}`,
            }}
            pagination={Items?.length > 1}
            breakpoints={breakpoints}
            className="bannerSwiper min-h-16 sm:min-h-24 md:min-h-32 lg:min-h-44 xl:min-h-60 rounded-lg overflow-hidden"
            loop
            autoplay={
              Items?.length > 1
                ? {
                    delay: 5000,
                  }
                : false
            }
            // onSlideChange={(swiper) => setCurrentBanner(Items[swiper.realIndex])}
          >
            {Items.map((banner, index) => (
              <SwiperSlide key={index}>
                <BannerItem banner={banner} onGoToSucursal={handleGoToSucursal} mostrarCronometro={mostrarCronometro}/>                
              </SwiperSlide>
            ))}
            {/* <button
              style={{
                display: Items?.length > 1 ? "flex" : "none",
              }}
              onClick={isPlaying ? pauseSwiper : playSwiper}
              className="absolute right-2 bottom-2 z-10 w-6 h-6 md:w-8 md:h-8 items-center justify-center text-white bg-black/30 hover:bg-black/60 rounded-full"
              slot="container-end"
            >
              <FontAwesomeIcon
                className="text-sm md:text-base opacity-100"
                icon={isPlaying ? faPause : faPlay}
              />
            </button> */}
          </Swiper>
          <button
            style={{
              display: Items?.length > 1 ? "block" : "none",
            }}
            className={`absolute z-10 top-0 bottom-0 right-0 md:static p-2 text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 review-swiper-button-next-${idSeccion} rounded-r-lg`}
          >
            <FontAwesomeIcon icon={faAngleRight} size="xl" />
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  banners: state.Home.banners,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  direccion: state.Location.direccion,
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {
  get_banners,
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
})(Banner);
