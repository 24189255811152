import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ItemEstadoPedido = ({estado,icono,activo}) => {
    return (
        <div className="d-flex flex-column items-center"   style={{"marginTop":"10px"}}>
            <div className={`rounded-3xl w-11 h-11 d-flex items-center justify-center  ${activo ? 'bg-hiperblue ' : 'bg-hipergris'} `}
          
            >
                <FontAwesomeIcon icon={icono} className="text-white text-xl" />
            </div>
            <label   className="text-sm text-center" >{estado}</label>
            
        </div>
    );
};

export default ItemEstadoPedido;