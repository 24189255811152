import React from "react";
import { calculateValueLength } from "./timer-utils";

type DigitProps = {
  value: number;
  title?: string;
  titleSmall?: string;
  isMilliseconds?: boolean;
  hideDots?: boolean;
  isDay?: boolean;
};

type DigitValueProps = {
  digit: string;
};

const DigitValue: React.FC<DigitValueProps> = ({ digit }) => {
  return (
    <span className="relative flex flex-1 font-bold text-[11px] leading-none md:text-xs lg:text-base lg:leading-6 bg-black rounded-sm md:rounded-md p-[0.25rem] md:px-2 md:py-1 text-white mr-0.5 ">
      {digit}
    </span>
  );
};

const DigitDot: React.FC = () => {
  return (
    <span className="w-0.5 h-0.5 lg:w-1 lg:h-1 bg-black rounded-full my-0.5 md:my-1" />
  );
};

const DigitDots: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <DigitDot />
      <DigitDot />
    </div>
  );
};


const Digit: React.FC<DigitProps> = ({
  value,
  title,
  titleSmall,
  isMilliseconds,
  hideDots,
  isDay,
}) => {
  const valueLength = calculateValueLength(value, !!isDay, !!isMilliseconds);
  let digits = value
    .toString()
    .padStart(valueLength, "0")
    .split("");

  return (
    <div className="flex flex-col flex-gr items-center">
      <div className="flex gap-1">
        <div className="flex flex-row p-0">
          {digits.map((digit, index) => (
            <DigitValue key={index} digit={digit} />
          ))}
          {/* {isMilliseconds && (
            <>
              <DigitValue digit={digits[0]} />
              <DigitValue digit={digits[1]} />
            </>
          )}
          <DigitValue digit={digits[2]} />
          <DigitValue digit={digits[3]} /> */}
        </div>
        {!hideDots && <DigitDots />}
      </div>
      {title && (
        <span className="w-full text-center text-[11px] mt-1 text-white font-semibold hidden md:block mr-1">
          {title}
        </span>
      )}
      {titleSmall && (
        <span className="w-full text-center text-[8px] mt-1 text-white font-semibold md:hidden mr-1">
          {titleSmall}
        </span>
      )}
    </div>
  );
};

export default Digit;
