export function contarDigitos(num: number): number {
  return num === 0 ? 1 : Math.floor(Math.log10(Math.abs(num))) + 1;
}

export function calculateValueLength(
  value: number,
  isDay: boolean,
  isMilliseconds: boolean
) {
  const count = contarDigitos(value);
  if (isDay) {
    return count < 2 ? 2 : count;
  }
  if (isMilliseconds) return 4;
  return 2;
}
