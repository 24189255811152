import {
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  POST_LOCATION_SUCCESS,
  POST_LOCATION_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  UPDATE_LOCATION_FAIL,
  GET_LOCATION_ACTUAL,
  SET_LOCATION_PRINCIPAL,
  SET_LOCATION_OMITIDO,
  CLEAR_ADDRESS,
} from "./types";
import axios from "axios";
// import {setAlert} from "./alert";
import { setSwAlert, showRespuesta } from "./swalert";
import { end_peticion, start_peticion } from "./peticion";
/*
import { setAlert } from '../reducers/alert'; */
import respuestas from "../../components/Alertas";

export const limpiar_direccion = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ADDRESS,
  });
};

export const get_ubicacion = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones`,
      config
    );
    if (res.status === 200 && res.data.Estado === 200) {
      dispatch({
        type: GET_LOCATION_SUCCESS,
        payload: res.data,
      });
      dispatch(set_ubicacion_actual(res.data.Dato));
    } else {
      dispatch({
        type: GET_LOCATION_FAIL,
      });

      dispatch(showRespuesta(res));
    }
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
  } catch (err) {
    //
    dispatch({
      type: GET_LOCATION_FAIL,
    });

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const save_datos_entrega_p = (datos) => async (dispatch) => {
  dispatch(start_peticion(""));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/datos-entrega`,
      datos,
      config
    );

    if (res.status === 200) {
      // dispatch({
      //     type: GET_LOCATION_SUCCESS,
      //     payload: res.data
      // });
    } else {
      // dispatch({
      //     type: GET_LOCATION_FAIL
      // });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    //
    dispatch({
      type: GET_LOCATION_FAIL,
    });
    dispatch(end_peticion());

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const post_inicio_ubicacion = (datos) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones`,
      datos,
      config
    );

    if (res.status === 200) {
      dispatch(get_ubicacion());
      dispatch({
        type: POST_LOCATION_SUCCESS,
        payload: res.data,
      });
      // dispatch(setAlert('Ubicación creada correctamente', 'green'));
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
    } else {
      dispatch({
        type: POST_LOCATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch({ type: REMOVE_AUTH_LOADING });
  } catch (err) {
    //
    /* if (err) {
            dispatch(setSwAlert(err.response.data.Message, true, 400));
            // dispatch(setSwAlert(err.response.data.error_description,true));
        }
        */
    dispatch({
      type: POST_LOCATION_FAIL,
    });
    dispatch({ type: REMOVE_AUTH_LOADING });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const post_ubicacion = (datos, object) => async (dispatch) => {
  dispatch(start_peticion("registrando..."));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones`,
      datos,
      config
    );

    let data = null;
    if (res.status === 200) {
      dispatch(get_ubicacion());
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200, object));
      data = res.data.Dato;
    } else {
      dispatch({
        type: POST_LOCATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
    return data;
  } catch (err) {
    //
    dispatch({
      type: POST_LOCATION_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    return null;
  }
};

export const update_ubicacion = (datos, id, object) => async (dispatch) => {
  dispatch(start_peticion("actualizando..."));

  dispatch({
    type: SET_LOCATION_PRINCIPAL,
    payload: datos,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones/${id}`,
      datos,
      config
    );

    if (res.status === 200) {
      dispatch(get_ubicacion());
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200, object));
    } else {
      dispatch({
        type: UPDATE_LOCATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: UPDATE_LOCATION_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const delete_ubicacion = (id) => async (dispatch) => {
  dispatch(start_peticion("actualizando..."));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    let url = `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones/${id}`;
    const res = await axios.delete(url, config);

    if (res.status === 200) {
      dispatch(get_ubicacion());
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
    } else {
      dispatch({
        type: UPDATE_LOCATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: UPDATE_LOCATION_FAIL,
    });

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    dispatch(end_peticion());
  }
};

export const cambiar_ubicacion = (ubicaciones, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    let ubicacion = ubicaciones.find((ub) => ub.Id == id);
    ubicacion.EsPrincipal = true;
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/ubicaciones/${id}`,
      ubicacion,
      config
    );

    if (res.status === 200) {
      dispatch(get_ubicacion());
    } else {
      dispatch({
        type: UPDATE_LOCATION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    // dispatch({type: REMOVE_AUTH_LOADING});
  } catch (err) {
    dispatch({
      type: UPDATE_LOCATION_FAIL,
    });
    dispatch({ type: REMOVE_AUTH_LOADING });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const set_ubicacion_actual = (array) => async (dispatch) => {
  let direccion = array.find((location) => location.EsPrincipal);
  dispatch({
    type: GET_LOCATION_ACTUAL,
    payload: direccion,
  });
  if (direccion) {
    dispatch({
      type: SET_LOCATION_PRINCIPAL,
      payload: direccion,
    });
  }
};

export const set_ubicacion_omitido = (estado) => async (dispatch) => {
  dispatch({
    type: SET_LOCATION_OMITIDO,
    payload: estado,
  });
};

export const post_inicio_ubicacion_existente = (datos) => async (dispatch) => {
  dispatch({
    type: SET_LOCATION_PRINCIPAL,
    payload: datos,
  });
};
