import React from "react";
import { useGetContactInfoLeyenda } from "../modules/shared/ui/hooks/use-get-contact-info-leyenda";
import clsx from "clsx";

const Leyenda: React.FC = () => {
  const {
    isLoadingSucursalContacto,
    sucursalContactoLeyendaColorFondo,
    sucursalContactoLeyendaColorLetra,
    sucursalContactoLeyenda,
  } = useGetContactInfoLeyenda();

  if (isLoadingSucursalContacto) return null;
  if (!sucursalContactoLeyenda) return null;
  return (
    <div
      className={clsx("p-2 text-center", {
        "bg-hiperorange": !sucursalContactoLeyendaColorFondo,
        "text-white": !sucursalContactoLeyendaColorLetra,
      })}
      style={{
        background: sucursalContactoLeyendaColorFondo || undefined,
        color: sucursalContactoLeyendaColorLetra || undefined
      }}
      dangerouslySetInnerHTML={{__html: sucursalContactoLeyenda}}
    />
  );
};

export default Leyenda;
