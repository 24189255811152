import { useSelector } from "react-redux";
import { SucursalContacto } from "../../../sucursal-contactos/domain/interface/sucursal-contacto";

export function useBuildSearchContactInfo() {
  const sucursalContacto = useSelector(
      (state: { SucursalContactos: { sucursalContacto: SucursalContacto } }) =>
        state.SucursalContactos.sucursalContacto
    );

    const isLoadingSucursalContacto = useSelector(
      (state: { SucursalContactos: { isLoading: boolean } }) =>
        state.SucursalContactos.isLoading
    );
    
  const mensajeBusquedaNoEncontradoNro = sucursalContacto?.MensajeBusquedaNoEncontradoNro?.trim();
  const imagenBusquedaUrl = sucursalContacto?.ImagenBusquedaUrl?.trim();
  const mensajeBusquedaNoEncontrado = sucursalContacto?.MensajeBusquedaNoEncontrado?.trim();
  return { mensajeBusquedaNoEncontrado, mensajeBusquedaNoEncontradoNro, imagenBusquedaUrl, isLoadingSucursalContacto };
}
