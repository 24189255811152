import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buildWhatsappLink } from "../modules/shared/ui/utils/build-whatsapp-link";
import { formatPhoneNumber } from "../modules/shared/ui/utils/format-phone-number";
import { useGetSucursalContactoTelefonos } from "../modules/sucursal-contactos/ui/hooks/useGetSucursalContactoTelefonos";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface MensajeTelefonoProductoDisponibleProps {
  mensaje?: string;
}
export function MensajeTelefonoProductoDisponible({mensaje = 'Te ayudamos a encontrar el producto que buscas, escríbenos al'}: MensajeTelefonoProductoDisponibleProps) {
  const { telefonoProductoNoDisponible } = useGetSucursalContactoTelefonos();

  if (!telefonoProductoNoDisponible) return null;
  return (
    <div className="text-gray-500 text-lg md:text-xl text-center md:flex md:items-center">
      {`${mensaje} `}
      <a
        href={buildWhatsappLink(telefonoProductoNoDisponible)}
        target="_blank"
        rel="noreferrer"
        className="p-2 py-1 text-[#128C7E] hover:text-[#075E54] rounded-lg flex md:inline-flex items-center justify-center gap-2 font-semibold underline"
      >
        <FontAwesomeIcon size="lg" icon={faWhatsapp} />
        {formatPhoneNumber(telefonoProductoNoDisponible)}
      </a>
    </div>
  );
}
