import React from "react";
import Digit from "./digit";
import DigitSmall from "./digit-small";

type TimerProps = {
  milliseconds: number;
  seconds: number;
  minutes: number;
  hours: number;
  days?: number;
  enableMilliseconds?: boolean;
  size?: "big" | "small";
};

const Timer: React.FC<TimerProps> = ({
  milliseconds,
  seconds,
  minutes,
  hours,
  days,
  enableMilliseconds,
  size = "big",
}) => {
  const isSmall = size === "small";
  return (
    <div className="flex flex-row items-center gap-1">
      {days !== undefined && (
        <>{!isSmall ? <Digit value={days} /> : <DigitSmall value={days} />}</>
      )}
      <>{!isSmall ? <Digit value={hours} /> : <DigitSmall value={hours} />}</>
      <>
        {!isSmall ? <Digit value={minutes} /> : <DigitSmall value={minutes} />}
      </>
      <>
        {!isSmall ? (
          <Digit value={seconds} hideDots={!enableMilliseconds} />
        ) : (
          <DigitSmall value={seconds} hideDots={!enableMilliseconds} />
        )}
      </>

      {enableMilliseconds && (
        <>
          {!isSmall ? (
            <Digit value={milliseconds} isMilliseconds hideDots />
          ) : (
            <DigitSmall value={milliseconds} isMilliseconds hideDots />
          )}
        </>
      )}
    </div>
  );
};

export default Timer;
