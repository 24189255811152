import {
  SucursalContactosAction,
  SucursalContactosState,
  SET_SUCURSAL_CONTACTO,
} from "../types/sucursal-contactos-types";

const initialState: SucursalContactosState = {
  sucursalContacto: null,
  isLoading: true
};

export default function SucursalContactos(
  state: SucursalContactosState = initialState,
  action: SucursalContactosAction
) {
  const { type, payload } = action;

  switch (type) {
    case SET_SUCURSAL_CONTACTO:
      return {
        ...state,
        sucursalContacto: payload,
        isLoading: false
      };

    default:
      return state;
  }
}
