import { faArrowUp, faCartCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "../../src/assets/styles/categoria.scss";
import Layout from "../hocs/Layout";
import {
  get_producto,
  limpiar_array_producto,
  limpiar_search,
} from "../redux/actions/producto";
import { get_categorias } from "../redux/actions/home";
import ItemProducto from "../widgets/item_producto";
import LoaderSpinner from "../widgets/loaderSpinner";
import Alert from "../components/Swalert";
import { useScrollToElementTop } from "../hooks/useScrollToElementTop";
import { useSearchTerm } from "../hooks/useSearchTerm";
import BuscadorGeneral from "../widgets/BuscadorGeneral";
import CategoriasCollapseDesktop from "./CategoriasCollapseDesktop";
import { MensajeTelefonoProductoDisponible } from "./MensajeTelefonoProductoDisponible";
import { useBuildSearchContactInfo } from "../modules/shared/ui/hooks/use-build-search-contact-info";
import { LazyLoadImage } from "../components/lazyLoadImage";

const SearchProducto = ({
  sucursal,
  limpiar_search,
  limpiar_array_producto,
  get_producto,
  productos,
  productos_peticion,
  peticion,
  end_message,
  profile,
  get_categorias,
}) => {
  const {
    imagenBusquedaUrl,
    mensajeBusquedaNoEncontrado,
    mensajeBusquedaNoEncontradoNro,
    isLoadingSucursalContacto,
  } = useBuildSearchContactInfo();
  const { scrollToElementTop } = useScrollToElementTop({ id: "scrollableDiv" });
  const { searchTerm: producto } = useSearchTerm();
  let IdSucursal = sucursal ? sucursal.IdSucursal : null;
  let IdMarket = sucursal ? sucursal.IdMarket : null;
  const [filtro, setFiltro] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
  }, [sucursal.IdSucursal, sucursal.IdMarket]);

  const isUserAuthenticated = useMemo(() => {
    if (!profile) return null;
    return profile?.Telefono != null && profile?.Telefono !== "";
  }, [profile]);

  useEffect(() => {
    if (producto !== filtro) {
      setFiltro(producto || "");
      setPage(1);
      mover_scroll();
    }
  }, [producto, filtro, isUserAuthenticated]);

  const subir = () => {
    mover_scroll();
  };

  const mover_scroll = () => {
    scrollToElementTop();
  };

  const cargarProductos = useCallback(
    async (datos, pageAux) => {
      await get_producto(datos, pageAux);
    },
    [get_producto]
  );

  useEffect(() => {
    async function fetchData() {
      const valorProducto = producto || "";
      try {
        if (!productos_peticion) {
          await limpiar_array_producto();
        }
        let datos = {
          IdMarket: IdMarket,
          IdLocatorio: IdSucursal,
          IdCategoria: null,
          IdSubcategoria: null,
          texto_filtro: valorProducto,
        };

        if (valorProducto === filtro && isUserAuthenticated !== null)
          cargarProductos(datos, page);
      } catch (error) {}
    }

    fetchData();
  }, [
    producto,
    filtro,
    IdSucursal,
    IdMarket,
    page,
    cargarProductos,
    isUserAuthenticated,
  ]);

  const obtenerCantidad = () => {
    return productos.length;
  };

  const mensajeNoEncontrado =
    !isLoadingSucursalContacto && mensajeBusquedaNoEncontrado
      ? mensajeBusquedaNoEncontrado
      : "No encontramos productos que coincidan con tu búsqueda.";

  return (
    <Layout>
      <div className="block sticky top-0 lg:hidden p-2 z-20 bg-white">
        <BuscadorGeneral />
        <div className="flex gap-2 items-center my-2">
          <h4 className="text-center text-black block">Buscar en categorías</h4>
        </div>
      </div>
      <Alert></Alert>
      <main className="relative flex-grow ">
        <CategoriasCollapseDesktop />

        <div className="md:ml-60 lg:ml-72">
          <div className="w-full p-4 px-3">
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={obtenerCantidad()}
              next={() => {
                const valorProducto = producto || "";

                if (valorProducto === filtro) {
                  setPage((prevPage) => prevPage + 1);
                }
              }}
              hasMore={true}
              loader={
                peticion || isUserAuthenticated === null ? (
                  <LoaderSpinner bool={peticion}></LoaderSpinner>
                ) : (
                  end_message && (
                    <div className="flex w-full justify-center">
                      {obtenerCantidad() > 0 ? (
                        <p className="text-gray-400 ">
                          No hay más productos para mostrar
                        </p>
                      ) : (
                        <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                          {imagenBusquedaUrl ? (
                            <div className="max-w-32 w-full">
                              <div className="w-full  h-0 pb-[100%] relative transition duration-200 ease-in-out transform group-hover:scale-105 rounded-lg overflow-hidden">
                                <LazyLoadImage
                                  src={imagenBusquedaUrl}
                                  className="absolute top-0 left-0 w-full h-full object-cover"
                                  alt="Imagen de búsqueda"
                                />
                              </div>
                            </div>
                          ) : (
                            <FontAwesomeIcon
                              className="text-gray-500"
                              icon={faCartCircleXmark}
                              size="6x"
                            />
                          )}
                          <div className="flex flex-col gap-2">
                            <div className="text-gray-500 text-lg md:text-xl text-center">
                              {mensajeNoEncontrado}
                            </div>
                            <MensajeTelefonoProductoDisponible mensaje={mensajeBusquedaNoEncontradoNro || undefined}/>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>No hay más productos para mostrar</b>
                </p>
              }
            >
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-x-3 md:gap-x-6 gap-y-8">
                {productos &&
                  productos !== null &&
                  productos !== undefined &&
                  productos.map((producto, index) => (
                    <ItemProducto
                      key={index}
                      producto={producto}
                      ps_IdSucursal={sucursal.IdSucursal}
                    />
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
        {/* <button
          className="footer-button  bg-hiperorange hover:bg-hiperorange_hover opacity-80 text-white font-bold py-1 px-3 rounded"
          type="button"
          onClick={() => subir()}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button> */}
      </main>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  sucursales: state.BranchOffice.sucursales,
  productos_peticion: state.Producto.productos_peticion,
  loading: state.Producto.loading,
  productos: state.Producto.productos,
  peticion: state.Peticion.peticion,
  end_message: state.Producto.end_message,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_producto,
  limpiar_array_producto,
  limpiar_search,
  get_categorias,
})(SearchProducto);
