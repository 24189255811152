import React, { useState } from "react";
import { LazyLoadImage } from "../lazyLoadImage";
import Chronometer from "../timer/chronometer";
import { useStartChronometer } from "../../hooks/use-start-chronometer";

type BannerItemProps = {
  banner: any;
  onGoToSucursal: (url: string) => void;
  mostrarCronometro: boolean;
};

const BannerItem: React.FC<BannerItemProps> = ({
  banner,
  onGoToSucursal,
  mostrarCronometro = false,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleOnGoToSucursal = (url: string) => {
    onGoToSucursal(url);
  };

  const { startChronometer, chronometerEndDate } = useStartChronometer({
    startDate: banner.FechaInicioCronometro,
    endDate: banner.FechaFin,
  });

  const displayTimer = mostrarCronometro && startChronometer && imageLoaded;

  return (
    <a
      href={banner.Url_Destino}
      onClick={(e) => {
        e.preventDefault();
        handleOnGoToSucursal(banner.Url_Destino);
      }}
      // reloadDocument
      className="group relative flex flex-col gap-2 h-full"
    >
      {displayTimer && chronometerEndDate && (
        <div className="flex w-full self-end md:right-2 md:top-2">
          <Chronometer expiryTimestamp={chronometerEndDate} />
        </div>
      )}
      {/* <div className="h-full transition duration-200 ease-in-out transform group-hover:scale-105"> */}
      <div className="mt-auto">
        <LazyLoadImage
          src={banner.UrlFoto}
          alt=""
          className="w-full rounded-lg"
          style={undefined}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
    </a>
  );
};

export default BannerItem;
